<template>
  <div class="md-layout">
    <div class="md-layout-item">
      <div class="btn-row">
        <md-button class="md-success" @click="planProfile">
          {{ $t('buttons.add') }}
        </md-button>
      </div>
      <md-card>
        <md-card-header class="md-card-header-icon md-card-header-green">
          <div class="card-icon">
            <md-icon>group</md-icon>
          </div>
          <h4 class="title">{{ $t('plans.list') }}</h4>
        </md-card-header>

        <md-card-content>
          <md-table
            :value="queriedData"
            @md-selected.native="onSelect"
            :md-sort.sync="currentSort"
            :md-sort-order.sync="currentSortOrder"
            :md-sort-fn="customSort"
            class="paginated-table table-striped table-hover"
          >
            <md-table-toolbar>
              <md-field>
                <label for="pages">{{ $t('label.per_page') }}</label>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item"
                  >
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field>
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  :placeholder="$t('label.search_records')"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </md-table-toolbar>

            <md-table-row
              slot="md-table-row"
              slot-scope="{item}"
              :style="{
                'background-color': Boolean(item.locked_at) ? '#ccddff' : ''
              }"
            >
              <md-table-cell :md-label="$t('plans.name')" md-sort-by="name">
                {{ item.name }}
              </md-table-cell>
              <md-table-cell
                :md-label="$t('plans.created_at')"
                md-sort-by="created_at"
              >
                {{ item.created_at | dateFormat }}
              </md-table-cell>
              <md-table-cell
                :md-label="$t('plans.locked_at')"
                md-sort-by="locked_at"
              >
                {{ item.locked_at | dateFormat }}
              </md-table-cell>
              <md-table-cell
                :md-label="$t('plans.deleted_at')"
                md-sort-by="deleted_at"
              >
                {{ item.deleted_at | dateFormat }}
              </md-table-cell>
              <md-table-cell :md-label="$t('tables.actions')">
                <div class="cell-actions">
                  <md-button
                    class="md-default md-raised md-sm"
                    @click.native="handleEdit(item)"
                  >
                    {{ $t('buttons.edit') }}
                  </md-button>
                  <md-button
                    v-if="false"
                    class="md-just-icon md-danger md-simple"
                    @click.stop.prevent="handleDelete(item)"
                  >
                    <md-icon>close</md-icon>
                  </md-button>
                </div>
              </md-table-cell>
            </md-table-row>
          </md-table>
          <div class="footer-table md-table">
            <table>
              <tfoot>
                <tr>
                  <th
                    v-for="item in footerTable"
                    :key="item.name"
                    class="md-table-head"
                  >
                    <div class="md-table-head-container md-ripple md-disabled">
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </md-card-content>
        <md-card-actions md-alignment="space-between">
          <div class>
            <p class="card-category">
              {{
                $t('label.showing_from_to_of_entries', {
                  from: to > 0 ? from + 1 : 0,
                  to: to,
                  total
                })
              }}
            </p>
          </div>
          <pagination
            class="pagination-no-border pagination-success"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></pagination>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>
<script>
import {Pagination} from '@/components'
import Fuse from 'fuse.js'
//import Swal from 'sweetalert2'
import {mapState} from 'vuex'

export default {
  name: '',
  filters: {
    dateFormat: function(value) {
      if (!value) return ''
      value = value.toString().substring(0, 19)
      return value
    }
  },
  data() {
    return {
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: '',
      propsToSearch: ['name', 'created_at', 'locked_at', 'deleted_at'],
      searchedData: [],
      currentSort: 'name',
      currentSortOrder: 'asc',
      fuseSearch: null,
      footerTable: []
    }
  },
  components: {
    Pagination
  },
  methods: {
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy]
            .toString()
            .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
          .toString()
          .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    },
    handleEdit(item) {
      const {id} = item
      this.$router.push(`plan_upd/${id}`)
    },
    planProfile() {
      this.$router.push(`plan_add`)
    }
  },
  mounted() {
    this.$store.dispatch('LOAD_PLAN_LIST').then(() => {
      // Fuse search initialization.
      this.fuseSearch = new Fuse(this.tableData, {
        keys: this.propsToSearch,
        threshold: 0.3
      })
    })
  },
  computed: {
    ...mapState({
      planList: (state) => state.Plans.list
    }),
    queriedData() {
      const result = !this.searchQuery
        ? this.tableData
        : this.searchedData
        ? this.searchedData
        : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.planList
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
  }
}
</script>
<style lang="scss" scoped>
.locked-plan {
  background-color: #ccddf3;
}
.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}
.md-table-head-label {
  padding-right: 0;
}
.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-button + .md-button {
  margin-left: 10px;
}
</style>
